import {
  BaseStepIdEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: 'company_form',

    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'company_form',
          isEnabled: true,
          type: FieldTypeEnum.select,
          isRequired: true,
          options: [
            'private_company',
            'public_company',
            'association',
            'self_employed',
          ],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: false },
  },
];
